export default (pathname, queryString) => {
    let utmParams = "";
    const srcToAppend = "utm_source";
    const query = window.location.search;
    if (query && query.includes(srcToAppend)) {
        if (query.startsWith(`?${srcToAppend}`)) {
            utmParams = query;
        } else {
            utmParams = `?${  query.slice(query.indexOf(srcToAppend))}`;
        }
    }
    return {
        pathname,
        search: (queryString ? `${queryString}${utmParams ? "&" : ""}${utmParams.slice(1)}` : utmParams) || ""
    };
};
