import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import PopularCategory from "./component";

// Actions
import { updateAppliedFilters, updateFilterOptionData, clearAllFilters, setSearchResultTrackingData } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";
import { updateOpenAICarFinderModal } from "../../ae.configuration/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        selectedFilters
    },
    config: {
        activeSaleConfig
    }
}) => ({
    allFilters,
    selectedFilters,
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAppliedFiltersConnect: updateAppliedFilters,
    reloadCarListConnect: reloadCarList,
    clearAllFiltersConnect: clearAllFilters,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData,
    updateOpenAICarFinderModalConnect: updateOpenAICarFinderModal
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PopularCategory);
