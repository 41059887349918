import { FASTLY_BASE_URL } from "../../../constants/app-constants";

export const BANNER_KEY = {
    "EPIC_SALE": "EPIC_SALE",
    "BANK_OFFER": "BANK_OFFER",
    "PARTNER_OFFER": "PARTNER_OFFER",
    "ANDROID": "ANDROID",
    "IOS": "IOS",
    "FINANCE": "FINANCE",
    "HOME_TD_ALL": "HOME_TD_ALL",
    "MONEYBACK": "MONEYBACK",
    "SELL_CAR": "SELL_CAR",
    "WARRANTY": "WARRANTY",
    "ZERO_DOWNPAYMENT": "ZERO_DOWNPAYMENT",
    YARD_BANNER_SHARJAH: "YARD_BANNER_SHARJAH",
    "AI_CAR_BANNER": "AI_CAR_BANNER",
    "PUSH_FP_UNLOCK_BANNER": "PUSH_FP_UNLOCK_BANNER"
};

export const BANNER_MAP = {
    [BANNER_KEY.ANDROID]: "banner_android",
    [BANNER_KEY.IOS]: "banner_ios",
    [BANNER_KEY.FINANCE]: "finance_01",
    [BANNER_KEY.HOME_TD_ALL]: "home-test-drive-listing",
    [BANNER_KEY.MONEYBACK]: "money_back_01",
    [BANNER_KEY.SELL_CAR]: "sell_car",
    [BANNER_KEY.WARRANTY]: "warranty_01",
    [BANNER_KEY.ZERO_DOWNPAYMENT]: "zero_down_payment_01",
    [BANNER_KEY.EPIC_SALE]: "nd_epic_drop_sale",
    [BANNER_KEY.BANK_OFFER]: "nd_bank_offer",
    [BANNER_KEY.PARTNER_OFFER]: "nd_partner_offers",
    [BANNER_KEY.YARD_BANNER_SHARJAH]: "yard_banner_sharjah",
    [BANNER_KEY.AI_CAR_BANNER]: "ai_find_car_banner"
};

export const BANNER_EVENT_LABEL = {
    [BANNER_KEY.FINANCE]: "Quick & Easy Fin",
    [BANNER_KEY.FREE_HOME_TD]: "Free TD",
    [BANNER_KEY.MONEYBACK]: "Moneyback",
    [BANNER_KEY.SELL_CAR]: "SellCar",
    [BANNER_KEY.WARRANTY]: "Warranty",
    [BANNER_KEY.ZERO_DOWNPAYMENT]: "Zero DP",
    [BANNER_KEY.EPIC_SALE]: "epic_drop",
    [BANNER_KEY.BANK_OFFER]: "bank_offers",
    [BANNER_KEY.PARTNER_OFFER]: "partner_offers"
};

export const BANNER_BASE_URL = `${FASTLY_BASE_URL}/ae/car-list/`;
export const DOWNLOAD_BANNER_BASE_URL = `${FASTLY_BASE_URL}/ae/content/`;
export const BANNER_SALE_URL = `${FASTLY_BASE_URL}/ae/banner/`;
