import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// Styling
import styles from "./styles.css";

// Components
import Button from "../../shared/button";
import LazyImage from "../../shared/lazy-image";

// Utils
import Filters from "../../../utils/filters-v2";
import { FILTER_ENTITIES, FILTER_TYPES, POPULAR_CATEGORY_OPTIONS_V2 } from "../../../utils/filters-v2/constants";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import getUtmUrl from "../../../utils/helpers/get-utm-url";

// Constants
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import { defaultFilterPayload } from "../car-listing/constants";
import { NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { BANNER_SALE_URL } from "../value-proposition-card-v2/constant";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import { uncategorizedEvents } from "../../../tracking/msite/uncategorized-events";

const imgMapping = {
    asGoodAsNew: {
        imgSrc: "as-good-as-new",
        imgAlt: "Cheap new cars in Dubai - UAE"
    },
    budgetFriendly: {
        imgSrc: "budget-friendly",
        imgAlt: "Cheap pre owned cars in Dubai - UAE"
    },
    latestSUVs: {
        imgSrc: "latest-suvs",
        imgAlt: "Cheap pre owned SUVs in Dubai - UAE"
    },
    luxuryInBudget: {
        imgSrc: "luxury-in-budget",
        imgAlt: "Used Luxury cars in Dubai - UAE"
    },
    onSale: {
        imgSrc: "on-sale",
        imgAlt: "Popular categories"
    },
    premiumSedan: {
        imgSrc: "premium-sedans",
        imgAlt: "Premium sedan second hand cars in Dubai -UAE"
    },
    aiCarFinder: {
        imgSrc: "ai-finder-pc-banner",
        imgAlt: "Ai Car Finder"
    },
    saleLive: {
        imgSrc: "on-sale-live",
        imgAlt: "Sale live"
    }
};

const PopularCategory = ({
    source = "",
    title = "",
    hideCta = false,
    isFromSearchModal = false,
    selectedCity = {},
    allFilters = {},
    selectedFilters = [],
    toggelSearchBarModal = () => { },
    updateFilterOptionDataConnect,
    updateAppliedFiltersConnect,
    reloadCarListConnect,
    clearAllFiltersConnect,
    setSearchResultTrackingDataConnect,
    updateOpenAICarFinderModalConnect,
    activeSaleConfig
}) => {

    const groupFacet = allFilters?.[FILTER_ENTITIES.CATEGORY]?.groupFacet || {};
    const [isPopularCategorySelected, setIsPopularCategorySelected] = useState(false);
    const [facets, setFacets] = useState(groupFacet);
    const { customPush } = useCustomHistoryOperations();

    const { active: isSaleLive = false, saleDetails = {}} = activeSaleConfig || {};
    const {homePopularCategory = {}} = saleDetails || {};

    const { subTitle: popularCategorySaleText = "", imageUrlMsite: popularCategorySaleImageUrl} = homePopularCategory || {};

    useEffect(() => {
        const clonedFacets = { ...groupFacet };
        if (isSaleLive) {
            clonedFacets.saleLive = {
                displayText: popularCategorySaleText,
                imageUrl: popularCategorySaleImageUrl,
                key: "saleLive"
            };
            delete clonedFacets.onSale;
        }
        clonedFacets.aiCarFinder = {
            displayText: "AI Car Finder ",
            key: "aiCarFinder"
        };
        delete clonedFacets.premiumSedan;
        setFacets({ ...clonedFacets });
    }, [groupFacet, isSaleLive, popularCategorySaleImageUrl, popularCategorySaleText]);

    const navigateToListing = async () => {
        const filters = new Filters({ cityCode: selectedCity?.code, cityName: selectedCity?.name });
        const { relativeURL } = filters.getListingPayload({ selectedFilters, filters: allFilters, options: {} });
        await customPush(relativeURL);
        updateAppliedFiltersConnect();
        reloadCarListConnect(true);
        setIsPopularCategorySelected(false);
        toggelSearchBarModal();
        scrollToTop();
    };

    useEffect(() => {
        const selectedFiltersKey = selectedFilters.map((filterType) => filterType.filterKey);
        const isCategoryFilterApplied = selectedFiltersKey.includes(FILTER_ENTITIES.CATEGORY);
        const isDiscountFilterApplied = selectedFiltersKey.includes(FILTER_ENTITIES.DISCOUNT);
        if (isPopularCategorySelected && (isCategoryFilterApplied || isDiscountFilterApplied)) {
            navigateToListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

    const goToSeller = async () => {
        await customPush(getUtmUrl(SELLER_HOME_ROUTE));
        toggelSearchBarModal();
        scrollToTop();
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.buySellMyCar, { eventLabel: "one_liner" });
    };

    const onClickFindCar = async () => {
        clearAllFiltersConnect();
        setSearchResultTrackingDataConnect({ filterType: "Homepage" });
        const filters = new Filters({ cityCode: selectedCity?.code, cityName: selectedCity?.name });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload, isGlobalUrl: true, hasCitySkip: selectedCity?.hasCitySkip });
        await customPush(relativeURL);
        toggelSearchBarModal();
        scrollToTop();
        trackPlatformCustomEventsAEV2(b2cHomePageEvents.viewAllCarsHome, { eventLabel: "NA" });
    };

    const handleAICarFinderClick = () => {
        updateOpenAICarFinderModalConnect("popular_category");
    };

    const handleCategoryClick = async (item = {}) => {
        clearAllFiltersConnect();
        setSearchResultTrackingDataConnect({ filterType: "Homepage" });
        const { displayText, key, options = [] } = item || {};
        if (key === "aiCarFinder") {
            handleAICarFinderClick();
        } else if (key === "saleLive") {
            updateFilterOptionDataConnect({
                filterKey: FILTER_ENTITIES.DISCOUNT,
                optionKey: "discount",
                subOptionKey: options?.[NUMBER.ZERO]?.key,
                isSelected: true,
                filterType: FILTER_TYPES.GF,
                displayText
            });
            setIsPopularCategorySelected(true);
            trackPlatformCustomEventsAEV2(b2cHomePageEvents.popularCategorySaleItemClick, {eventLabel: popularCategorySaleText});
        } else {
            updateFilterOptionDataConnect({
                filterKey: FILTER_ENTITIES.CATEGORY,
                optionKey: key,
                subOptionKey: options?.[NUMBER.ZERO]?.key,
                isSelected: true,
                filterType: FILTER_TYPES.GF,
                displayText
            });
            setIsPopularCategorySelected(true);
        }

        if (isFromSearchModal) {
            trackPlatformCustomEventsAEV2(uncategorizedEvents.popularCategoriesModal, { eventCategory: `Buy_used_cars_B2C_${source === ROUTE_NAME.LISTING ? "Listing" : "Home"}_Page` });
        } else {
            trackPlatformCustomEventsAEV2(b2cHomePageEvents.popularCategories, { eventLabel: displayText });
        }
    };

    return (
        <Fragment>
            <div styleName={"styles.wrapper"} className="newUserWrap">
                <h2>{title ? title : "Used cars for sale in UAE by category"}</h2>
                <div styleName={"styles.popularCategoryGrid"}>
                    <ul>
                        {Object.keys(facets || {})?.map((keyName) => {
                            let imageSrc = keyName !== "saleLive" ? `${BANNER_SALE_URL}${imgMapping[keyName]?.imgSrc}.webp` :  facets?.[keyName]?.imageUrl;
                            const inlineStyle = keyName === "saleLive" ? {objectFit: "cover"} : {};
                            imageSrc = keyName === "aiCarFinder" ? `${imageSrc}?width=0.4` : imageSrc;
                            return (
                                POPULAR_CATEGORY_OPTIONS_V2.includes(keyName) && (
                                    <li key={keyName} onClick={() => handleCategoryClick(facets[keyName])}>
                                        <LazyImage src={imageSrc} width={90} height={90} alt={imgMapping[keyName]?.imgAlt} style={inlineStyle} />
                                        {keyName ===  "saleLive" ? <span styleName={"styles.liveNowText"}>Live Now</span> : ""}
                                        <span>{facets?.[keyName]?.displayText}</span>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                    {!hideCta && (
                        <Fragment>
                            <Button text="VIEW ALL CARS" onClick={onClickFindCar} />
                            <p onClick={goToSeller}>Not looking to buy. Click here to <span>Sell your car</span></p>
                        </Fragment>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

PopularCategory.propTypes = {
    source: PropTypes.string,
    title: PropTypes.string,
    isSaleLive: PropTypes.bool,
    selectedCity: PropTypes.object,
    allFilters: PropTypes.object,
    selectedFilters: PropTypes.array,
    updateFilterOptionDataConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    setSearchResultTrackingDataConnect: PropTypes.func,
    isShowResumeSessionSection: PropTypes.bool,
    hideCta: PropTypes.bool,
    isFromSearchModal: PropTypes.bool,
    toggelSearchBarModal: PropTypes.func,
    updateOpenAICarFinderModalConnect: PropTypes.func,
    activeSaleConfig: PropTypes.object
};

export default withRouter(PopularCategory);
